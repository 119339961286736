import React, { Component } from "react";
import { subscribe } from "../utils/bunifu-redux";
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Icon,
  Layout,
  Menu,
  Progress,
  Tooltip,
} from "antd";

import { get, get2, put } from "../utils/Storage";

import Dashboard from "./pages/Dashboard/Dashboard";
import Trips from "./pages/Trips";
import Students from "./pages/Students/Students";
import Guardians from "./pages/Guardians";
import Users from "./pages/Users/Users";
import Schedules from "./pages/Schedules/Schedules";
import Vehicles from "./pages/Vehicles";
import Attendace from "./pages/Attendance/Attendance";
import Routes from "./pages/Routes/Routes";
import Reports from "./pages/Reports";
import Tools from "./pages/Tools";
import Dashboard2 from "./pages/Dashboard/Dashboard2";
import * as Njia from "../utils/Routing";
import { Params, Route } from "../utils/Routing";
import ManageUser from "./pages/Users/ManageUser";
import ManageVehicle from "./pages/ManageVehicle";
import NotFound from "./pages/NotFound";
import ImportStudents from "./pages/ImportStudents.js";
import auth from "../Components/Auth";
import Lock from "./pages/Lock";
import axios from "axios";
import ProviderList from "./pages/Providers/ProviderList";
import ProviderProfile from "./pages/Providers/ProviderProfile";
import Routes2 from "./pages/Routes/Routes2";
import Stations from "./pages/Stations/Stations";
import moment from "moment";
import GoSp from "../Components/GoSp";
import GoSync from "./pages/Sync/GoSync";
import Go from "../Server/Go";
import _ from "linq";

////////////////////////LAYOUT
class AppLayout extends Component {
  state = {
    name: "AppLayout",
    page: "dashboard",
    user: get("admin"),
    permissions: get2("permissions"),
    route: Route(),
  };

  componentWillMount() {
    subscribe(this);
  }

  componentDidMount() {
    //Session.set("hello","wold");

    //listen(r => this.setState({route: r}))

    //check for user access control changes
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + get("admin").access_token;
    setInterval(
      () => {
        axios.get("/user").then((r) => {
          let payload = get("admin").user.payload;
          if (r.data.payload != payload) {
            let admin = get("admin");
            admin.user.payload = r.data.payload;
            put("admin", admin);
            put("account", admin);
            setTimeout(window.location.reload(), 5000);
          }
        });
      },
      300000 //  secounds
    );
  }

  render() {
    let reportPage = Params().page;

    const { SubMenu } = Menu;
    const { Header, Content, Footer, Sider } = Layout;
    const menu = (
      <Menu style={{ width: "300px", padding: 22 }}>
        <Menu.Item disabled={true} style={{ color: "#1F7B6C" }}>
          <Icon type={"database"} /> {window.year}
        </Menu.Item>
        <Menu.Item disabled={true} style={{ color: "#1F7B6C" }}>
          <Icon type={"check"} /> {get("admin").role}
        </Menu.Item>
        <Menu.Item disabled={true} style={{ color: "#1F7B6C" }}>
          <Icon type={"user"} /> {get("admin").user.name}
        </Menu.Item>
        <Menu.Item disabled={true} style={{ color: "#1F7B6C" }}>
          <Icon type={"mail"} /> {get("admin").user.email}
        </Menu.Item>
        {get("admin").user.phone.length > 7 && (
          <Menu.Item disabled={true} style={{ color: "#1F7B6C" }}>
            <Icon type={"phone"} />{" "}
            {"*** " + get("admin").user.phone.substring(7)}
          </Menu.Item>
        )}

        {/*<GoWidget color={"#01C68A"}*/}
        {/*          className={""}*/}
        {/*          title={get("admin").user.email} icon={"user"}*/}
        {/*          value={get("admin").role}/>*/}
        <Menu.Divider />
        <Menu.Item>
          <a
            target={"_blank"}
            href={
              window.location.origin + "/#/m-user?id=" + get("admin").user.id
            }
          >
            My Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          <a target={"_blank"} href={window.location.origin + "/#/reset"}>
            Change Password
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a
            onClick={() => {
              // reset data
              var admin = get("admin");
              var version = get("version");
              localStorage.clear();
              put("admin", admin);
              put("version", version);
              window.location.replace(
                window.location.pathname +
                  window.location.search +
                  window.location.hash
              );
              window.location.reload();
            }}
            rel="noopener noreferrer"
            href="#"
          >
            Sync Database
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            onClick={() => {
              // reset data
              localStorage.clear();
              window.location.replace(
                window.location.pathname +
                  window.location.search +
                  window.location.hash
              );
              window.location.reload();
            }}
            rel="noopener noreferrer"
            href="#"
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );
    const years = [
      ...new Set(
        get("schedules")
          .filter((r) => r.Year > 0)
          .map((item) => item.Year)
      ),
    ];

    /**
         *
         {this.state.route=="reports" && <Reports page = {reportPage} />}
         */

    var curpage = <NotFound />;
    var navbarHidden = true;

    switch (this.state.route) {
      case false:
        navbarHidden = false;
        curpage = <Dashboard2 />;
        break;

      case null:
        navbarHidden = false;
        curpage = <Dashboard2 />;
        break;

      case "":
        navbarHidden = false;
        curpage = <Dashboard2 />;
        break;
      case "analytics":
        navbarHidden = false;
        curpage = <Dashboard />;
        break;
      case "dashboard":
        navbarHidden = false;
        curpage = <Dashboard2 />;
        break;

      case "trips":
        navbarHidden = false;
        curpage = <Trips />;
        break;

      case "attendance":
        navbarHidden = false;
        curpage = <Attendace />;
        break;

      case "students":
        navbarHidden = false;
        curpage = (
          <Students
            onFilter={(s) => {
              if (Go.getUser().isAdmin()) return true;

              let vehicles = Go.getUser()?.getProvider()?.getVehicles() || [];

              //TODO

              return vehicles.length
                ? _.from(s.schedules).any((sch) =>
                    vehicles?.any((v) => v.id == sch.VehicleId)
                  )
                : [];
            }}
          />
        );
        break;

      case "guardians":
        navbarHidden = false;
        curpage = <Guardians />;
        break;
      case "vehicles":
        navbarHidden = false;
        curpage = <Vehicles />;
        break;
      case "users":
        navbarHidden = false;
        curpage = <Users />;
        break;
      case "schedules":
        navbarHidden = false;
        curpage = <Schedules />;
        break;
      case "routes2":
        navbarHidden = false;
        curpage = <Routes2 />; // <Routes/>;
        break;
      case "routes":
        navbarHidden = false;
        curpage = <Routes />;
        break;
      case "stations":
        navbarHidden = false;
        curpage = <Stations />; // <Routes/>;
        break;
      case "tools":
        navbarHidden = false;
        curpage = <Tools />;
        break;
      case "m-user":
        navbarHidden = true;
        curpage = <ManageUser />;
        break;
      case "access-control":
        navbarHidden = true;
        curpage = <ManageUser />;
        break;
      case "m-vehicle":
        navbarHidden = true;
        curpage = <ManageVehicle />;
        break;
      case "providers":
        navbarHidden = false;
        curpage = <ProviderList />;
        break;

      case "provider":
        navbarHidden = true;
        curpage = <ProviderProfile />;
        break;

      case "reports":
        navbarHidden = false;
        curpage = <Reports page={reportPage} />;
        break;

      case "import":
        navbarHidden = true;
        curpage = <ImportStudents />;
        break;
      default:
        break;
    }

    if (!auth.Authorize(this.state.route)) {
      navbarHidden = true;
      curpage = <Lock />;
    }

    let curYear = moment().year();
    let admin = get("admin");
    let user = admin.user;

    const MenuIcon = (props) => (
      <>
        <Divider type={"vertical"} />
        <GoSp />
        <img
          src={"./img/menu/" + (props.name || props.type || props.src) + ".png"}
          width={19}
          style={{ marginTop: -4 }}
        />
        <GoSp />
      </>
    );

    // if (user.providerid == null)
    //     curpage = <Lock/>
    // else
    //     curpage = <ProviderProfile
    //         provider={_.from(get("providers")).firstOrDefault(r => r.id == user.providerid)}/>

    return (
      <Layout>
        <div
          className="noprint"
          style={{
            isolation: "isolate",
            position: "fixed",
            zIndex: 10,
            width: "100%",
          }}
        >
          <Progress
            strokeColor={{
              from: "#1EBD60",
              to: "#2E515A",
            }}
            style={{
              width: "102%",
              marginLeft: "-1%",
              marginTop: "-10px",
              float: "left",
            }}
            percent={100}
            showInfo={false}
            status="active"
          />

          <div style={{ marginTop: 10, float: "right" }} hidden={navbarHidden}>
            <div>
              <GoSync compact={true} />
              <GoSp />
              {auth.Access(auth.AccessBeta) && (
                <>
                  <Tooltip placement={"left"} title={"Global Search"}>
                    <Button
                      type={"primary"}
                      size={"large"}
                      icon={"search"}
                      shape={"round"}
                    />
                  </Tooltip>
                  <GoSp />
                  <Tooltip placement={"bottomRight"} title={"Quick Add Record"}>
                    <Button
                      type={"outline"}
                      size={"large"}
                      icon={"plus"}
                      shape={"round"}
                    />
                  </Tooltip>
                </>
              )}
              <GoSp size={4} />
              <Divider style={{ height: "27px" }} type={"vertical"} />
              <GoSp />
              <Tooltip
                placement={"left"}
                title={
                  "Hi! " +
                  user.name +
                  (admin.role == "Administrator"
                    ? ""
                    : " | " +
                      (
                        user.providerid != null &&
                        get("providers-map")[user.providerid]
                      ).Name)
                }
              >
                <Dropdown
                  overlay={menu}
                  trigger={"click"}
                  placement="bottomRight"
                >
                  <Avatar
                    className={"shadow"}
                    style={{ backgroundColor: "#2E515A" }}
                    size={37}
                    icon="user"
                  />
                </Dropdown>
              </Tooltip>

              <GoSp size={8} />
            </div>
          </div>

          {/*<h1 hidden={navbarHidden} className={'gradient'}*/}
          {/*    style={{float: 'right', textAlign: 'right', marginTop: 8, marginRight: 50}}>*/}
          {/*    <b>Year {curYear} </b>*/}
          {/*</h1>*/}

          <img
            style={{
              height: "53px",
              cursor: "pointer",
              float: "left",
              marginTop: 2,
              marginBottom: 5,
              marginLeft: "35px",
              marginRight: 50,
              paddingTop: 8,
              paddingBottom: 6,
            }}
            onClick={() => {
              if (Njia.CanGoBack()) Njia.GoBack();
            }}
            className="darkmode-ignore"
            //  src={Njia.CanGoBack() ? "./img/back.png" : "./img/header.png"}
            src="./img/go-green.png"
          />

          <span style={{ float: "left", marginLeft: "19px", height: "100%" }}>
            {/*<Divider className={"shadow"}*/}
            {/*         style={{*/}
            {/*             height: "44px",*/}
            {/*         }}*/}
            {/*         dashed*/}
            {/*         type="vertical"/>*/}
          </span>

          <Menu
            mode="horizontal"
            selectedKeys={[this.state.route]}
            style={{
              width: "100%",
              backgroundColor: "#F0F2F5",
              zIndex: 1,
              height: "80px",
              boxShadow:
                " 0 6px 8px 0 rgba(46, 81, 90, 0.25), 0 6px 20px 0 rgba(46, 81, 90, 0.5)",
            }}
            onSelect={(page) => {
              Route(page.key);
            }}
            hidden={navbarHidden}
          >
            {/*<Divider*/}

            {/* Dashboard */}
            {this.state.permissions.get(1) && (
              <Menu.Item
                key="dashboard"
                style={{ marginLeft: "26px", marginTop: 22.2 }}
              >
                <MenuIcon type={"dashboard"} /> Dashboard
              </Menu.Item>
            )}

            {/* Students */}
            {this.state.permissions.get(3) && auth.View(auth.Students) && (
              <Menu.Item key="students">
                <MenuIcon type={"students"} /> Students
              </Menu.Item>
            )}

            {/* Attendance */}
            {this.state.permissions.get(11) &&
              auth.View(auth.AccessAttendance) &&
              Go.getUser().isAdmin() && (
                <Menu.Item key="attendance">
                  <MenuIcon type={"attendance"} />
                  <span>Attendance</span>
                </Menu.Item>
              )}

            {/* Transportation */}
            <SubMenu
              key={"transport"}
              title={
                <span>
                  <MenuIcon type={"transportation"} /> Transportation
                </span>
              }
            >
              {this.state.permissions.get(8) && auth.View(auth.AccessTrips) && (
                <Menu.Item key="trips">
                  <Icon type="environment" />
                  {this.state.user.role === "Administrator" ? (
                    <span>Trips</span>
                  ) : (
                    <span>My Trips</span>
                  )}
                </Menu.Item>
              )}
              {this.state.permissions.get(14) && auth.View(auth.Schedules) && (
                <Menu.Item key="schedules">
                  <Icon type="clock-circle" />
                  {this.state.user.role === "Administrator" ? (
                    <span>Schedules</span>
                  ) : (
                    <span>My Schedules</span>
                  )}
                </Menu.Item>
              )}
              <Divider dashed />

              {this.state.permissions.get(28) && auth.View(auth.Providers) && (
                <Menu.Item key="providers">
                  <Icon type="user" />
                  <span>Providers</span>
                </Menu.Item>
              )}

              {this.state.permissions.get(12) && (
                <Menu.Item key="vehicles" hidden={auth.noView(auth.Vehicles)}>
                  <Icon type="car" />
                  {this.state.user.role === "Administrator" ? (
                    <span>Vehicles</span>
                  ) : (
                    <span>My Vehicles</span>
                  )}
                </Menu.Item>
              )}

              {this.state.permissions.get(13) && (
                <Menu.Item key="routes" hidden={auth.noView(auth.Routes)}>
                  <Icon type="branches" />
                  {this.state.user.role === "Administrator" ? (
                    <span>Routes</span>
                  ) : (
                    <span>My Routes</span>
                  )}
                </Menu.Item>
              )}

              {/*{*/}
              {/*    Env.isDevelopment() &&*/}
              {/*    <Menu.Item key="stations" hidden={auth.noView(auth.Routes)}>*/}
              {/*        <Ant.Tag color="green">Dev</Ant.Tag> Stations*/}
              {/*    </Menu.Item>*/}
              {/*}*/}
            </SubMenu>

            <SubMenu
              key="sub1"
              hidden={this.state.user.role !== "Administrator"}
              title={
                <span>
                  <MenuIcon type={"users"} /> Users
                </span>
              }
            >
              {this.state.permissions.get(5) && Go.getUser().isAdmin() && (
                <Menu.Item key="guardians">
                  <Icon type="team" />
                  <span>Guardians</span>
                </Menu.Item>
              )}

              {this.state.permissions.get(6) && (
                <Menu.Item key="users">
                  <Icon type="team" />
                  <span>Staff</span>
                </Menu.Item>
              )}
            </SubMenu>

            {/*{Might use the pages later}*/}
            {/*<SubMenu key="SubMenu"*/}
            {/*         title={<span>*/}
            {/*        <MenuIcon type={"attendance"}/>*/}
            {/*             <span>Attendance</span>*/}
            {/*</span>}>*/}
            {/*    <Menu.Item key={"attendance"}>*/}
            {/*        ⌚<Divider type={"vertical"}/>Live activity</Menu.Item>*/}
            {/*    <Menu.Item key={"attendance "}><a href={"wwwroot/?attendance-daily"}*/}
            {/*                                      target={"_blank"}>*/}
            {/*        ⌚<Divider type={"vertical"}/>Daily Activity</a></Menu.Item>*/}
            {/*    <Menu.Item key={"attendance  "}><a href={"wwwroot/?attendance-monthly"}*/}
            {/*                                       target={"_blank"}>*/}
            {/*        ⌚<Divider type={"vertical"}/>Monthly Activity</a></Menu.Item>*/}

            {/*</SubMenu>*/}

            <Divider type={"vertical"} />
            {this.state.permissions.get(15) && (
              <SubMenu
                key="reports"
                title={
                  <span hidden={auth.noView(auth.AccessReports)}>
                    <Tooltip title={"Reports"} placement={"left"}>
                      {/*<Icon type="printer"/>*/}
                      🖨️
                    </Tooltip>
                    {/*{this.state.user.role === "Administrator" ? <span>Reports</span> :*/}
                    {/*    <span>My Reports</span>*/}
                    {/*}*/}
                  </span>
                }
              >
                <Menu.ItemGroup title={"Student Reports"}>
                  {this.state.permissions.get(16) && (
                    <Menu.Item
                      key="reports?page=students-daily"
                      hidden={auth.noView(auth.StudentActivityReport)}
                    >
                      📊️
                      <Divider type={"vertical"} />
                      <span>Student Daily Activity</span>
                    </Menu.Item>
                  )}

                  {this.state.permissions.get(17) && (
                    <Menu.Item
                      key="reports?page=s-a-calendar"
                      hidden={auth.noView(auth.StudentAttendanceReport)}
                    >
                      📊️
                      <Divider type={"vertical"} />
                      <span>Student Attendance Activity</span>
                    </Menu.Item>
                  )}
                </Menu.ItemGroup>
                <Menu.ItemGroup title={"Billing Reports"}>
                  <Menu.Item
                    key="reports?page=students"
                    hidden={auth.noView(auth.StudentActivityReport)}
                  >
                    📊️
                    <Divider type={"vertical"} />
                    <span>Student Billing</span>
                  </Menu.Item>
                  <Menu.Item
                    key="reports?page=vehicles"
                    hidden={auth.noView(auth.VehicleActivityReport)}
                  >
                    📊️
                    <Divider type={"vertical"} />
                    <span>Vehicle Summary</span>
                  </Menu.Item>

                  <Menu.Item
                    key="reports?page=vehicle-earnings"
                    hidden={auth.noView(auth.VehicleActivityReport)}
                  >
                    📊️
                    <Divider type={"vertical"} />
                    <span>Monthly Vehicle Earnings</span>
                  </Menu.Item>

                  <Menu.Item
                    key="reports?page=all-vehicle-summary-earnings"
                    hidden={auth.noView(auth.VehicleActivityReport)}
                  >
                    📊️
                    <Divider type={"vertical"} />
                    <span>All Vehicle Summary Earnings</span>
                  </Menu.Item>

                  <Menu.Item
                    key="reports?page=providers"
                    hidden={auth.noView(auth.ProviderActivityReport)}
                  >
                    📊️
                    <Divider type={"vertical"} />
                    <span>Provider Summary</span>
                  </Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title={"Agent Performance"}>
                  <Menu.Item
                    key="reports?page=agents"
                    hidden={auth.noView(auth.AgentActivityReport)}
                  >
                    📊️
                    <Divider type={"vertical"} />
                    <span>Agent Summary</span>
                  </Menu.Item>
                  <Menu.Item
                    key="reports?page=t-calendar"
                    hidden={auth.noView(auth.TripTimelineReport)}
                  >
                    📊️
                    <Divider type={"vertical"} />
                    <span>Schedule Timeline</span>
                  </Menu.Item>
                  <Menu.Item
                    key="reports?page=missed"
                    hidden={auth.noView(auth.MissedTripsReport)}
                  >
                    📊️
                    <Divider type={"vertical"} />
                    <span>Missed Trips</span>
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
            )}

            {auth.View(auth.AccessTools) && (
              <Menu.Item key="tools">
                <Tooltip title={"Admin Tools"} placement={"right"}>
                  🛠️ {/*<Icon type="setting"/>*/}
                </Tooltip>
              </Menu.Item>
            )}
            <Divider type={"vertical"} />
          </Menu>
        </div>
        <Content style={{ marginTop: 73, width: "100%", height: "100%" }}>
          <Layout style={{ margin: 20, backgroundColor: "white" }}>
            {curpage}
          </Layout>
        </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    );
  }
}

export default AppLayout;
