import {get} from '../utils/Storage';

const auth = {
    // AccessDashboard: "Dashboard",
    CanSendSMS: "SendSMS",
    Students: "Students",
    AccessStudentBilling: "StudentBilling",
    AccessStudentProfile: "StudentInfo",
    Guardians: "Guardians",
    Users: "Users",
    AccessPermissions: "AccessControl",
    AccessTrips: "Trips",
    AccessTripInfo: "TripInfo",
    AccessTripTracker: "TripTracker",
    AccessAttendance: "Attendance",
    Vehicles: "Vehicles",
    Routes: "Routes",
    Schedules: "Schedules",
    AccessReports: "Reports",
    StudentActivityReport: "StudentActivityReport",
    StudentAttendanceReport: "StudentAttendaceReport",
    VehicleActivityReport: "VehicleActivityReport",
    ProviderActivityReport: "ProviderActivityReport",
    AgentActivityReport: "AgentActivityReport",
    TripTimelineReport: "TripTimelineReport",
    MissedTripsReport: "MissedTripsReport",
    AccessPromotionWizard: "PromotionWizard",
    AccessImportWizard: "ImportWizard",
    AccessAuditReport: "AuditReport",
    AccessDataBrowser: "DataBrowser",
    AccessTools: "Tools",
    Providers: "Providers",
    AccessBeta: "AccessBeta"
};

//

const moduleRoutesMap = {
    // dashboard: auth.AccessDashboard,
    students: auth.Students,
    guardians: auth.Guardians,
    users: auth.Users,
    trips: auth.AccessTrips,
    attendance: auth.AccessAttendance,
    vehicles: auth.Vehicles,
    "m-vehicle": auth.Vehicles,
    routes: auth.Routes,
    schedules: auth.Schedules,
    tools: auth.AccessTools,
    Providers: auth.Providers,
};


auth.Authorize = (module) => {
    if (!moduleRoutesMap[module])
        return true; //page does not exits - give pass

    return _get(moduleRoutesMap[module], "access");
}
const _get = (module, _type) => {
    let admin = get("admin");
    let payload = admin.user.payload;
    try {
        payload = JSON.parse(payload);
        return payload[module][_type];
    } catch (e) {
        return true;
    }
}


auth.View = (module) => _get(module, "access");
auth.Access = (module) => auth.View(module);
auth.Allow = (module) => auth.View(module);

auth.Update = (module) => _get(module, "modify");
auth.Insert = (module) => _get(module, "add");
auth.Delete = (module) => _get(module, "delete");

//invert
auth.noView = (module) => !auth.View(module);
auth.noAccess = (module) => !auth.View(module);
auth.no = (module) => !auth.View(module);

auth.noUpdate = (module) => !auth.Update(module);

auth.noInsert = (module) => !auth.Insert(module);

auth.noDelete = (module) => !auth.Delete(module);


// // Hackathon -  finale - Weekend
// // Auth.students.View
//
// for (const moduleName in auth) {
//     // We Can Mutate a JavaScript Object Initialized With "const"
//     const value = auth[moduleName];
//     auth[moduleName.toLowerCase()] = (invert = false) =>
//         invert ? auth.noAccess(moduleName)
//             : auth.Access(moduleName);
//     auth[moduleName.toLowerCase()] = (invert = false) =>
//         invert ? auth.noAccess(moduleName)
//             : auth.Access(moduleName);
//
// }


export default auth;
