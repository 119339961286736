import React, { Component } from "react";
import {
  setState,
  subscribe,
  getState,
  getInstance,
} from "../../utils/bunifu-redux";
import { Layout, Card, Tabs, DatePicker, Icon, Select, Col, Row } from "antd";
import moment from "moment";
import { Button } from "antd/lib/radio";
import { get } from "../../utils/Storage";
import { Params, Route } from "../../utils/Routing";

class Reports extends Component {
  state = {};

  render() {
    return (
      <iframe
        src={"/wwwroot/?" + this.props.page}
        // src={"http://localhost:5273/?" + this.props.page}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          position: "absolute",
          top: 125,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      ></iframe>
    );
  }
}
export default Reports;
